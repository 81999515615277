import { useCallback } from 'react'
import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import {
  Button,
  Link,
  CheckmarkCircleFillIcon,
  ConfirmationPendingContent,
  Modal,
  InjectedModalProps,
  ModalProps,
  AutoColumn,
  ColumnCenter,
  ExternalLinkIcon,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { getBlockExploreLink, getBlockExploreName } from '../../utils'
import AddToWalletButton, { AddToWalletTextOptions } from '../AddToWallet/AddToWalletButton'

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const { t } = useTranslation()

  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

  return (
    <AutoColumn mt="20px">
      <ColumnCenter>
        <CheckmarkCircleFillIcon width="140px" />
      </ColumnCenter>
      <AutoColumn mt="16px" gap="12px" justify="center">
        {chainId && hash && (
          <Link external small href={getBlockExploreLink(hash, 'transaction', chainId)}>
            <Button variant="primary-text">
              {t('View on %site%', {
                site: getBlockExploreName(chainId),
              })}

              <ExternalLinkIcon width="16px" height="16px" ml="4px" />
            </Button>
          </Link>
        )}
        {currencyToAdd && (
          <AddToWalletButton
            variant="gray"
            mt="8px"
            width="fit-content"
            marginTextBetweenLogo="6px"
            textOptions={AddToWalletTextOptions.TEXT_WITH_ASSET}
            tokenAddress={token?.address}
            tokenSymbol={currencyToAdd.symbol}
            tokenDecimals={token?.decimals}
            tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
          />
        )}
        <Button onClick={onDismiss} variant="gray" border="1px solid #FFFFFF33" mt="16px" width="100%">
          {t('Close')}
        </Button>
      </AutoColumn>
    </AutoColumn>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<
  React.PropsWithChildren<InjectedModalProps & ConfirmationModalProps & ModalProps>
> = ({ title, onDismiss, customOnDismiss, attemptingTxn, hash, pendingText, content, currencyToAdd, ...props }) => {
  const { chainId } = useActiveChainId()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal title={title} headerBackground="gradientCardHeader" {...props} onDismiss={handleDismiss}>
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
