import { useTranslation } from "@pancakeswap/localization";
import { AutoColumn } from "../../components/Column";
import { Dots, Text } from "../../components";

export function ConfirmationPendingContent({ pendingText }: { pendingText?: string }) {
  const { t } = useTranslation();
  return (
    <AutoColumn gap="4px" justify="center" mt="40px">
      {pendingText ? (
        <>
          <Dots as={Text} color="gray100" fontSize="20px">
            {t("Waiting For Confirmation")}
          </Dots>
          <AutoColumn gap="12px" justify="center">
            <Text color="gray100" medium small textAlign="center">
              {pendingText}
            </Text>
          </AutoColumn>
        </>
      ) : null}
      <Text small color="gray200" textAlign="center">
        {t("Confirm this transaction in your wallet")}
      </Text>
    </AutoColumn>
  );
}
